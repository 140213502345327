import React, { useContext, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import Layout from '../../../common/components/Layout'
import { SeoData } from '../../../common/types/Seo'
import route from '../../../common/utils/route'
import { t } from '../../../common/i18n'
import { BreadCrumbItems } from '../../../common/components/breadCrumb/LocalizedBreadCrumb'
import AppContext, { IAppState } from '../../../common/context/context'
import { PaginationResult } from '../../../shared/catalogo/type/pagination'
import Catalogo from '../../../shared/catalogo/Catalogo'
import { Producto } from '../../../shared/catalogo/producto/producto'
import { CatalogoProvider } from '../../../shared/catalogo/CatalogoContext'
import Size from '../../../common/utils/media-queries-setup'
import { Aceite } from './api/aceite'
import { mapAceiteToProducto } from './mapAceiteToProducto'
import { catalogoListAction } from '../../../shared/catalogo/store/list/catalogoListActions'
import {
  selectCatalogo,
  selectCatalogoPagination,
} from '../../../shared/catalogo/store/list/catalogoListSelectors'
import { catalogoFilterValuesAction } from '../../../shared/catalogo/store/filter/catalogoFilterValuesAction'
import {
  selectCatalogoFilterValues,
  selectCatalogoFilterValuesLoading,
} from '../../../shared/catalogo/store/filter/catalogoFilterValuesSelectors'
import { CatalogoSearchParams } from '../../../shared/catalogo/type/filter'
import AceitesFilter from './AceitesFilter'
import { AceitesFilterValues } from './api/aceitesFiltro'
import {
  AceitesFilters,
  initialAceitesFilterSections,
  initialAceitesFilterValues,
} from './aceitesCatalogoTypes'
import * as CestaActions from '../../../common/context/actions/cestaActions'
import { DataSteps, Steps } from '../../../common/components/cesta/Steps'

export interface AceitesProps {
  data: {
    seoData: SeoData
  }
  aceites: Producto[]
  filterValues: AceitesFilterValues
  pagination: PaginationResult
  getAceites: (data: CatalogoSearchParams<AceitesFilters>) => Promise<void>
  getFilterValues: () => void
}

const breadCrumItems = [
  { display: t('bread_crumb.inicio'), link: route('index') },
  { display: t('bread_crumb.coche'), link: route('coche') },
  { display: t('bread_crumb.aceites') },
] as BreadCrumbItems

export const Aceites: React.FC<AceitesProps> = ({
  data,
  aceites,
  filterValues,
  pagination,
  getAceites,
  getFilterValues,
}) => {
  const { dispatch } = useContext(AppContext)

  const isMobile = Size.useMedia({
    mobile: true,
    tablet: false,
    desktop: false,
  })

  useEffect(() => {
    getFilterValues()
    getAceites(initialAceitesFilterValues)
  }, [])

  const onSelectAceite = (aceite: Producto) => {
    dispatch(
      CestaActions.addProduct(
        {
          id: aceite.id_navision,
          type: 'aceite',
        },
        'aceites'
      )
    )
    dispatch(CestaActions.setStep(Steps.TU_SOLICITUD))
    navigate(DataSteps[Steps.TU_SOLICITUD].route)
  }

  const productos = useMemo(() => aceites?.map(mapAceiteToProducto), [aceites])

  return (
    <Layout
      seoData={data.seoData}
      breadCrumItems={breadCrumItems}
      selector={false}>
      <CatalogoProvider
        name={'aceites'}
        filterSections={{ ...filterValues, ...initialAceitesFilterSections }}
        initialParams={initialAceitesFilterValues}
        getProducts={getAceites}
        getFilters={getFilterValues}
        isMobile={isMobile}>
        <Catalogo
          title={t('aceites.title')}
          productos={productos}
          pagination={pagination}
          onSelectProducto={onSelectAceite}
          filterComponent={<AceitesFilter />}
        />
      </CatalogoProvider>
    </Layout>
  )
}
const mapStateToProps = (state: IAppState) => {
  return {
    aceites: selectCatalogo<Aceite>(state, 'aceites'),
    filterValues: selectCatalogoFilterValues(state, 'aceites'),
    pagination: selectCatalogoPagination(state, 'aceites'),
    loadingFilterValues: selectCatalogoFilterValuesLoading(state, 'aceites'),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAceites: (data: CatalogoSearchParams<AceitesFilters>) =>
      dispatch(catalogoListAction<AceitesFilters>('aceites', data)),
    getFilterValues: () => dispatch(catalogoFilterValuesAction('aceites')),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Aceites)
