import { graphql } from 'gatsby'
import React from 'react'
import Aceites from '../../../../src/coche/aceites/catalogo/Aceites'

const AceitesPage = ({ data }) => <Aceites data={data} />
export default AceitesPage

export const pageQuery = graphql`
  query aceitesQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
