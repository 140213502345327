import {
  CatalogoFilters,
  CatalogoSearchParams,
  FilterSection,
} from '../../../shared/catalogo/type/filter'
import { PAGE_SIZE } from '../../../shared/catalogo/store/list/types.d'

export interface FilterAceitesSections extends FilterSection {
  marcasExpanded: boolean
  viscosidadExpanded: boolean
  cantidadExpanded: boolean
}
export interface AceitesFilters extends CatalogoFilters {
  marca: string[]
  viscosidad: string[]
  cantidad: string[]
}

export const initialAceitesFilterValues: CatalogoSearchParams<AceitesFilters> =
  {
    filters: {
      marca: [],
      viscosidad: [],
      cantidad: [],
    },
    pagination: {
      page: 1,
      pageSize: PAGE_SIZE,
    },
    order: {
      label: 'Recommended',
      value: 'orden.asc',
      field: 'orden',
      direction: 'ASC',
    },
  }

export const initialAceitesFilterSections: FilterAceitesSections = {
  marcasExpanded: true,
  viscosidadExpanded: true,
  cantidadExpanded: true,
}
