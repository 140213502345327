import React, { ChangeEvent } from 'react'
import styles from '../../../shared/catalogo/filter/filters.module.scss'
import { t } from '../../../common/i18n'
import CheckBoxYellow from '../../../shared/components/Checkbox/CheckBox'
import ListFilterSection from '../../../shared/components/List/ListFilterSection/ListFilterSection'
import { useCatalogoContext } from '../../../shared/catalogo/CatalogoContext'

const AceitesFilter = () => {
  const { filters, searchParams, filterChange, toggleFilterSection } =
    useCatalogoContext()

  return (
    <>
      <ListFilterSection
        titleId="baterias.filters.marca"
        expanded={filters.marcasExpanded}
        toggleSection={() => toggleFilterSection('marcasExpanded')}>
        {filters &&
          filters.marcas?.map((marca) => (
            <div className={styles.filterSidebar__label} key={Math.random()}>
              <CheckBoxYellow
                size="xsmall"
                name={marca.id_site_marca_aceite}
                checked={searchParams.filters.marca?.includes(
                  String(marca.id_site_marca_aceite)
                )}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value
                  filterChange(
                    'marca',
                    e.target.checked
                      ? [...searchParams.filters.marca, value]
                      : searchParams.filters.marca.filter((id) => id !== value)
                  )
                }}
                label1={marca.marca}
                value={marca.id_site_marca_aceite}
                key={Math.random()}
              />
            </div>
          ))}
      </ListFilterSection>
      {/* Viscosidad */}
      <ListFilterSection
        titleId="aceites.filters.viscosidad"
        expanded={filters.viscosidadExpanded}
        toggleSection={() => toggleFilterSection('viscosidadExpanded')}>
        <div className={styles.filterSidebar__inputList}>
          {filters &&
            filters.viscosidad?.map((viscosidad: string) => {
              return (
                <div
                  className={styles.filterSidebar__label}
                  key={Math.random()}>
                  <CheckBoxYellow
                    size="xsmall"
                    label1={t(`aceites.filters.viscosidad_selector_label`, {
                      valor: viscosidad,
                    })}
                    name={`viscosidad_${viscosidad}`}
                    key={`viscosidad_key_${viscosidad}`}
                    value={viscosidad}
                    checked={searchParams.filters.viscosidad?.includes(
                      viscosidad
                    )}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value
                      filterChange(
                        'viscosidad',
                        event.target.checked
                          ? [...searchParams.filters.viscosidad, value]
                          : searchParams.filters.viscosidad.filter(
                              (item) => item !== value
                            )
                      )
                    }}
                  />
                </div>
              )
            })}
        </div>
      </ListFilterSection>

      {/* Cantidad */}
      <ListFilterSection
        titleId="aceites.filters.cantidad"
        expanded={filters.cantidadExpanded}
        toggleSection={() => toggleFilterSection('cantidadExpanded')}>
        <div className={styles.filterSidebar__inputList}>
          {filters &&
            filters.cantidad?.map((cantidad: string) => {
              return (
                <div
                  className={styles.filterSidebar__label}
                  key={Math.random()}>
                  <CheckBoxYellow
                    size="xsmall"
                    label1={t(`aceites.filters.cantidad_selector_label`, {
                      valor: cantidad,
                    })}
                    name="cantidad"
                    key={`cantidad_${cantidad}`}
                    value={cantidad}
                    checked={searchParams.filters.cantidad?.includes(cantidad)}
                    onChange={(event) => {
                      const value = event.target.value
                      filterChange(
                        'cantidad',
                        event.target.checked
                          ? [...searchParams.filters.cantidad, value]
                          : searchParams.filters.cantidad.filter(
                              (item) => item !== value
                            )
                      )
                    }}
                  />
                </div>
              )
            })}
        </div>
      </ListFilterSection>
    </>
  )
}

export default AceitesFilter
