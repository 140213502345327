import { locale, t } from '../../../common/i18n'
import { Producto } from '../../../shared/catalogo/producto/producto'
import { slugifyFichaUrl } from '../../../shared/catalogo/utils/slugifyFichaUrl'
import { route } from '../../../common/utils/route-server'

export const mapAceiteToProducto = (aceite: Producto): Producto => {
  const nombre = aceite[`nombre_producto_${locale}`]
  const nombreSlug = slugifyFichaUrl(nombre)
  const generatedRouteUrl = route('aceites.ficha', {
    nombre: nombreSlug,
    id_site_producto: aceite.id_site_producto,
  })
  return {
    ...aceite,
    ficha_url: generatedRouteUrl,
    specifications: [
      {
        label: t('aceites.list.elemento.capacidad'),
        value: `${aceite.cantidad}`,
      },
      {
        label: t('aceites.list.elemento.viscosidad'),
        value: aceite.viscosidad,
      },
    ],
    tipo: 'aceite',
  }
}
